import BaseBean from "@/utils/BaseBean";

export interface IFinancePiListDataObj {
    utilInst:FinancePiListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class FinancePiListUtil extends BaseBean{
    public dataObj:IFinancePiListDataObj

    constructor(proxy:any,dataObj:IFinancePiListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}